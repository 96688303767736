// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breakSpaces[data-v-27751719] {
  white-space: break-spaces;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/infoPopUpModal.vue","webpack://./infoPopUpModal.vue"],"names":[],"mappings":"AACA;EACE,yBAAA;ACAF","sourcesContent":["\n.breakSpaces{\n  white-space: break-spaces;\n}\n\n",".breakSpaces {\n  white-space: break-spaces;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
