// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursorPointer[data-v-dc687fa4] {
  cursor: pointer;
}
.v-list-item .v-list-item__title[data-v-dc687fa4] {
  line-height: 2.5 !important;
}
.v-list[data-v-dc687fa4] {
  padding: 0;
}
.v-list-item__icon[data-v-dc687fa4] {
  margin-right: 8px !important;
}
.v-card[data-v-dc687fa4]:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.textColor[data-v-dc687fa4] {
  color: #008000;
}
.v-theme--dark .listColor[data-v-dc687fa4] {
  color: white !important;
}
.v-theme--light .listColor[data-v-dc687fa4] {
  color: rgba(0, 0, 0, 0.87) !important;
}
.ctrb[data-v-dc687fa4] {
  left: 15px;
}
.iconsSize20[data-v-dc687fa4] {
  width: 20px !important;
  height: 20px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/sambaRoom.vue","webpack://./sambaRoom.vue"],"names":[],"mappings":"AAeA;EACE,eAAA;ACdF;ADgBA;EACE,2BAAA;ACbF;ADeA;EACE,UAAA;ACZF;ADcA;EACE,4BAAA;ACXF;ADaA;EACE,gBAAA;ACVF;ADYA;EACE,cAAA;ACTF;ADYA;EACE,uBAAA;ACTF;ADWA;EACE,qCAAA;ACRF;ADUA;EACE,UAAA;ACPF;ADSA;EACE,sBAAA;EACA,uBAAA;ACNF","sourcesContent":["\n// .coffeeBreakBadge {\n//   font-size: 10px !important;\n//   height: 16px !important;\n//   min-width: 16px !important;\n//   padding: 2px 6px !important;\n//   pointer-events: auto;\n//   position: absolute;\n//   text-align: center;\n//   text-indent: 0;\n//   top: auto;\n//   transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);\n//   white-space: nowrap;\n//   border-radius: 50% !important;\n// }\n.cursorPointer {\n  cursor: pointer;\n}\n.v-list-item .v-list-item__title {\n  line-height: 2.5 !important;\n}\n.v-list {\n  padding: 0;\n}\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n.textColor {\n  color: #008000;\n}\n\n.v-theme--dark .listColor {\n  color: white !important;\n}\n.v-theme--light .listColor {\n  color: rgba(0, 0, 0, 0.87) !important;\n}\n.ctrb {\n  left: 15px;\n}\n.iconsSize20{\n  width: 20px !important;\n  height: 20px !important;\n}\n",".cursorPointer {\n  cursor: pointer;\n}\n\n.v-list-item .v-list-item__title {\n  line-height: 2.5 !important;\n}\n\n.v-list {\n  padding: 0;\n}\n\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n\n.textColor {\n  color: #008000;\n}\n\n.v-theme--dark .listColor {\n  color: white !important;\n}\n\n.v-theme--light .listColor {\n  color: rgba(0, 0, 0, 0.87) !important;\n}\n\n.ctrb {\n  left: 15px;\n}\n\n.iconsSize20 {\n  width: 20px !important;\n  height: 20px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
